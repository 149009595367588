module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145142326-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Paul Gaumer","short_name":"starter","start_url":"/","background_color":"#fff","theme_color":"#fff","theme_color_in_head":false,"display":"minimal-ui","icon":"src/images/square-logo.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"cacheDigest":"c32b12c39f8b1d0681b9f819ac9bd327"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
